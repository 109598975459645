.second {
    display: flex;
    flex-wrap: wrap;
    margin-top: 150px;
    justify-items: center;
}

.right-prt-one {
    width: 60%;
    display: grid;
    flex-direction: column;
    justify-items: left;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: -100px;
    margin-left: -30px;
}

.right-prt-one img {
    width: 90%;
}

.left-prt-one {
    width: 40%;
}

.left-prt-one h2 {
    margin-right: 192px;
    font-weight: 600;
    font-size: 24px;
}

.left-prt-one ul {

    margin-left: 6px;
    width: 88%;
}

.left-prt-one ul li {
    margin-top: 40px;
    /* text-align: justify; */
    font-size: 18px;
    color: gray;
    font-family: 'Poppins';
    font-weight: 300;
}

@media screen and (max-width:600px) {
    .second {
        flex-direction: column-reverse;
    }

    .left-prt-one {
        width: 100%;
    }

    .left-prt-one ul {
        margin-left: 16px;
    }

    .left-prt-one ul li {
        margin-top: 15px;
    }

    .left-prt-one h2 {
        margin-right: 192px;
        font-weight: 600;
        font-size: 20px;
        width: 100%;
    }

    .right-prt-one {
        width: 100%;
        display: grid;
        flex-direction: column;
        justify-items: center !important;
        justify-content: center;
        align-items: center;
        align-content: center;
        margin-top: 0px;
    }

    .right-prt-one img {
        width: 90%;
        margin-top: 30px;
    }

    .second {
        display: flex;
        flex-wrap: wrap;
        margin-top: 50px;
        justify-items: center;
    }

    .second {
        display: flex;
        flex-wrap: wrap;
        margin-top: 50px;
        justify-items: center;
        margin: 15px;
    }


}