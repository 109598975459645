.refers {
    display: flex;
    margin-top: 100px;
}

.left-prt-two {
    width: 46%;
    margin-left: 150px;
    color: #3339;
}

.right-prt-two {
    width: 54%;
    display: grid;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.left-prt-two h2 {
    /* margin-left: 27px; */
    font-size: 24px;
    /* text-align: justify; */
    font-weight: 600;
    width: 98%;
    margin-bottom: 20px;
    color: #333;
}

.left-prt-two h3 {
    /* text-align: justify; */
    margin-left: 28px;
    color: gray;
    font-family: 'Poppins';
    /* line-height: 31px; */
    font-weight: 400;
    font-size: 16px;
    margin-top: 20px;
}

.right-prt-two img {
    width: 90%;
}

.left-prt-two img {
    position: absolute;
    left: 106px;
    /* top: 0px; */
    bottom: -1797px;
}

.group11 {
    background-image: url('../image/group11.svg');
    background-repeat: no-repeat;
    line-height: 30px;
    background-size: 100px 36px;
}

@media screen and (max-width:600px) {
    .left-prt-two {
        width: 100%;
    }

    .left-prt-two {
        /* width: 45%; */
        margin-left: 0px;
    }

    .left-prt-two h2 {
        font-size: 20px;
    }

    .refers {
        display: flex;
        /* margin-top: 100px; */
        justify-content: center;
        align-content: space-around;
        align-items: stretch;
        flex-direction: column;
    }

    .refers {
        display: flex;
        margin-top: 40px;
        margin-left: 15px;
        margin-right: 15px;

    }

    .right-prt-two {
        width: 90%;
        display: grid;
        flex-direction: column;
        justify-items: center;
        justify-content: center;
        align-items: center;
        align-content: center;
    }

    .right-prt-two img {
        width: 100%;
        padding-top: 40px;
        padding-bottom: 40px;
    }



}