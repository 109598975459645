.footer-main {
    background-color: #2F3F60;
}

.footer {
    display: flex;
    margin-top: 110px;
    /* background-color: #2F3F60; */
    padding-bottom: 15px;
}

.footer-left-thr {
    width: 50%;
    margin-top: 28px;
    margin-left: 100px;
}

.footer-right-thr {
    width: 50%;
    margin-top: 50px;
    margin-left: 25%;
}

.footer-left-thr img {
    width: 276px;
    margin-right: 351px;
    margin-bottom: -10px;
}

.footer-left-thr p {
    /* text-align: justify; */
    width: 85%;
    /* margin-left: 64px; */
    color: white;
    font-size: 14px;
    font-family: 'Poppins';
}



.footer-right-thr p {
    color: white;
}

.down-footer {
    display: flex;
    justify-content: space-between;
    padding: 10 15px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.down-footer p {
    color: white;
    margin-right: 100px;
}


.footer-main hr {
    width: 87%;
    margin: 10px auto;
    border-width: 1px;
    border-color: #d4d3d321;
}

/* .down-footer left{
    margin-left: 77px;
} */
.down-footer right {
    margin-right: 80px;
}

.footer-right-thr p {
    margin-right: 80px;
    /* text-align: right; */
    margin-bottom: 10px;
}

.right li {
    display: inline;
    padding: 1px 20px;
    text-decoration: none;
    color: white;
    /* margin-right: 50px; */
}

.right li a {
    display: inline;
    color: white;
    /* text-align: center;
    padding: 1px 10px; */
    text-decoration: none;
}

.left p {
    margin-left: 100px;
    font-size: 14px;
}

.right {
    margin-right: 100px;
    font-size: 14px;
}

@media screen and (max-width:600px) {
    .down-footer p {
        margin-right: 0px;

    }

    .down-footer div {
        /* width: 100%; */
        margin-bottom: 20px;
    }

    .footer {
        display: grid;
        justify-content: center;
        /* justify-items: center; */
        gap: 20px;
        flex-wrap: wrap;
    }

    .footer-left-thr img {
        width: 150px;
        margin-right: 0px;
    }

    .footer-left-thr {
        width: 50%;
        margin-top: 28px;
        margin-left: 0px;
    }

    .h4 {
        margin-right: 0px;
    }

    .p1 {
        margin-right: 0px;
    }

    .footer-left-thr {
        width: 90%;
    }

    .footer-left-thr p {
        text-align: justify;
        width: 100%;
        margin-left: 0px;
        color: white;
    }

    .footer {
        display: flex;
        margin-top: 0px;
        background-color: #2F3F60;
    }

    .footer-right-thr {
        display: grid;
        gap: 10px;
        width: 90%;
    }

    .footer-right-thr {
        /* width: 100%; */
        margin-top: 20px;
        margin-left: 0;
    }

    .down-footer {
        display: block;
    }

    .left p {
        margin-left: 0;
        padding-left: 15px;
        padding-right: 15px;
    }

    .right p {
        margin-left: 0;
        padding-left: 15px;
        padding-right: 15px;
    }

    .right ul {
        display: flex;
        flex-direction: column;
    }

    .right li {
        display: block;
        padding: 10px 20px;
        text-decoration: none;
        color: white;
        /* margin-right: 50px; */
    }



}