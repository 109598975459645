.box-one-half {
    display: flex;
    margin-right: 15px;
}

.box-one-full {
    display: flex;
}

.box-two {
    display: flex;
}

.box-three {
    display: flex;
}

.box-one {
    display: flex;
    /* margin-left: 30px; */
}

.box1 {
    border: 2px solid pink;
    width: 217px;
    height: 50px;
    border-radius: 8px;
    text-align: center;
    background-color: #f7524b1a;
    /* background-color: rgba(247, 82, 75, 0.4); */
}

.box1 img {
    width: 27px;
    /* margin-left: 13px; */
}

.box1 p {
    font-size: 18px;
    margin-block: auto;
}

.box2 {
    width: 438px;
    height: 50px;
    border: 2px solid pink;
    margin-top: 20px;
    border-radius: 8px;
    background-color: #f7524b1a;
}

.box3 p {
    text-align: justify;
    margin-left: 10px;
    width: 77%;
    font-size: 18px;
}

.box2 img {
    width: 29px;
    margin-block: auto;
    margin-left: 10px;
}

.box3 {
    width: 460px;
    height: 111pxpx;
    border: 2px solid pink;
    margin-top: 20px;
    border-radius: 8px;
    /* margin-left: 24px;  */
    background-color: #f7524b1a;
}

.box3 img {
    width: 29px;
    margin-block: auto;
    margin-left: 10px;
}

.box2 p {
    margin-block: auto;
    margin-left: 12px;
}

.upper-one {
    display: flex;
    align-items: center;
    justify-content: center;


}

.upper-one img {
    width: 37%;



}

.down-one {
    margin-top: 51px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.box2 {
    /* margin-left: 27px; */
    width: 460px;
}

@media screen and (max-width: 775px) {
    .upper-one img {
        width: 100%;
    }

    .box-one {
        display: flex;
        /* margin-left: 30px; */
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .box2 {
        /* margin-left: 27px; */
        width: 90%;
        justify-content: center;
    }

    .box3 {
        width: 90%;
        justify-content: center;
        margin-bottom: 20px;
    }

    .box-one-full {
        margin-top: 20px;
    }



    .box1 {
        width: 90%;
        justify-content: center;
    }

    .box-one-half {
        margin-right: 0;
    }

    .box3 p {
        /* text-align: justify;
        margin-left: 10px;
        width: 77%;
        font-size: 18px; */
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .center1 {
        height: 200px;
    }

}