/* PrivacyPolicy.css */

.privacy-policy-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.6;
}

.privacy-policy-container h1 {
    text-align: center;
    margin-bottom: 30px;
}

.privacy-policy-container h2 {
    margin-top: 20px;
    margin-bottom: 10px;
    color: #333;
}

.privacy-policy-container p {
    text-align: justify;
    margin-bottom: 15px;
}